<template>
  <div>
    <!-- page header component -->
    <page-header>
      <template #title>
        <b-tooltip
          v-if="hasRole('ROLE_BUSINESS_ADMIN') || checkOwnership()"
          type="is-primary"
          label="Click to edit this account"
          position="is-bottom"
        >
          <router-link
            tag="h1"
            class="title has-text-primary is-pointer"
            :to="{ name: 'EditAccount', params: { accountId: account.id } }"
          >
            <b-icon
              icon="edit"
              type="is-info"
              size="is-medium"
            />
            {{ account.name }}
          </router-link>
        </b-tooltip>

        <h1
          v-else
          class="title has-text-primary"
        >
          <b-icon icon="account" />
          <slot name="link" /> {{ account.name }}
        </h1>
      </template>

      <template #actions>
        <div class="level-item">
          <search-bar />
        </div>

        <div
          v-if="hasRole('ROLE_BUSINESS_ADMIN') || checkOwnership()"
          class="level-item"
        >
          <b-dropdown aria-role="list">
            <b-button
              v-if="account.name"
              slot="trigger"
              type="is-info"
              icon-left="plus"
              icon-right="chevron-down"
            >
              create
            </b-button>

            <template v-if="hasPermissions('create_accounts')">
              <b-dropdown-item
                aria-role="listitem"
                class="has-text-grey"
                @click="
                  $router.push(`/accounts/add?accountId=${account.id}&account=${account.name}`)
                "
              >
                Account
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <template v-if="hasPermissions('create_contacts')">
              <b-dropdown-item
                aria-role="listitem"
                class="has-text-grey"
                @click="
                  $router.push(`/contacts/add?accountId=${account.id}&account=${account.name}`)
                "
              >
                Contact
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <template v-if="hasPermissions('create_leads')">
              <b-dropdown-item
                aria-role="listitem"
                class="has-text-grey"
                @click="$router.push(`/leads/add?accountId=${account.id}&account=${account.name}`)"
              >
                Lead
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <template v-if="hasPermissions('create_leads')">
              <b-dropdown-item
                aria-role="listitem"
                class="has-text-grey"
                @click="
                  $router.push(`/opportunities/add?accountId=${account.id}&account=${account.name}`)
                "
              >
                Opportunity
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <template v-if="hasPermissions('create_activities')">
              <b-dropdown-item
                aria-role="listitem"
                class="has-text-grey"
                @click="
                  $router.push(`/activities/add?accountId=${account.id}&account=${account.name}`)
                "
              >
                Activity
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <b-dropdown-item
              v-if="hasPermissions('create_documents')"
              aria-role="listitem"
              class="has-text-grey"
              @click="openSidebar()"
            >
              Document
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div
          v-if="hasRole('ROLE_BUSINESS_ADMIN') || checkOwnership()"
          class="level-item"
        >
          <b-button
            icon-left="download"
            @click="$router.push(`/contacts/import?accountId=${account.id}`)"
          >
            import from outlook
          </b-button>
        </div>
      </template>
    </page-header>

    <section class="section">
      <div class="container">
        <b-breadcrumb
          size="is-small"
        >
          <b-breadcrumb-item
            tag="router-link"
            to="/profile"
          >
            Home
          </b-breadcrumb-item>
          <b-breadcrumb-item
            tag="router-link"
            to="/accounts"
          >
            Accounts
          </b-breadcrumb-item>
          <b-breadcrumb-item
            tag="router-link"
            to="/"
            active
          >
            {{ account.name }}
          </b-breadcrumb-item>
        </b-breadcrumb>

        <b-tabs
          v-model="activeTab"
          class="has-navbar-background"
        >
          <b-tab-item label="Overview">
            <div class="columns is-desktop">
              <div class="column is-three-fifths">
                <accounts-overview
                  :loading="isLoading"
                  :account="account"
                  :custom-fields-list="customFieldsList"
                />

                <hr class="divider">

                <div class="content">
                  <h3 class="subtitle is-capitalized has-text-primary has-text-weight-bold">
                    Financial Summaries from linked Hedberg Accounts
                  </h3>
                </div>

                <accounts-hedberg-table />
              </div>

              <div class="column">
                <div
                  v-if="hasRole('ROLE_BUSINESS_ADMIN') || checkOwnership()"
                  class="columns"
                >
                  <div class="column is-half">
                    <div
                      class="box has-background-primary is-pointer"
                      @click="setTab(3)"
                    >
                      <div class="content has-text-centered">
                        <b-icon
                          icon="bullseye"
                          type="is-white"
                          size="is-medium"
                        />
                        <p class="is-uppercase has-text-white">
                          opportunities
                        </p>
                        <p class="title has-text-white">
                          {{ account.opportunitiesCount }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="column">
                    <div
                      class="box has-background-info is-pointer"
                      @click="setTab(2)"
                    >
                      <div class="content has-text-centered">
                        <b-icon
                          icon="account-multiple"
                          type="is-white"
                          size="is-medium"
                        />
                        <p class="is-uppercase has-text-white">
                          leads
                        </p>
                        <p class="title has-text-white">
                          {{ account.leadsCount }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <template>
                  <activities-tabs
                    v-if="hasRole('ROLE_BUSINESS_ADMIN') || checkOwnership()"
                    @view-more="activeTab = 4"
                  />
                </template>

                <div class="columns">
                  <div class="column">
                    <div class="content">
                      <h4 class="subtitle has-text-primary is-uppercase">
                        Dependent Accounts
                      </h4>
                    </div>

                    <div
                      v-for="(childAccount, index) in childAccounts"
                      :key="index"
                      class="level"
                    >
                      <div class="level-left">
                        <div class="level-item">
                          <router-link
                            class="has-text-link has-text-weight-semibold"
                            :to="{
                              name: 'ViewAccount', params: { accountId: childAccountIds[index] } }"
                          >
                            {{ childAccount }}
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="hasRole('ROLE_BUSINESS_ADMIN') || checkOwnership()"
              class="columns"
            >
              <div class="column is-three-fifths">
                <notes-card-form
                  :notes="notes"
                  @submit-note="createNote"
                  @delete-note="deleteNote"
                />
              </div>
            </div>
          </b-tab-item>

          <b-tab-item label="Contacts">
            <contacts-table />
          </b-tab-item>

          <b-tab-item
            :visible="hasRole('ROLE_BUSINESS_ADMIN') || checkOwnership()"
            label="Leads"
          >
            <leads-table @convert-to-opportunity="convertToOpportunity" />
          </b-tab-item>

          <b-tab-item
            :visible="hasRole('ROLE_BUSINESS_ADMIN') || checkOwnership()"
            label="Opportunities"
          >
            <opportunities-table />
          </b-tab-item>

          <b-tab-item
            :visible="hasRole('ROLE_BUSINESS_ADMIN') || checkOwnership()"
            label="Activities"
          >
            <activities-table />
          </b-tab-item>

          <b-tab-item
            :visible="hasRole('ROLE_BUSINESS_ADMIN') || checkOwnership()"
            label="Documents"
          >
            <documents-table
              @edit-document="editDocument"
              @share-document="shareDocument"
            />

            <!-- documents form component -->
            <b-sidebar
              v-model="isSidebarActive"
              type="is-white"
              :fullheight="true"
              :overlay="false"
              :right="true"
            >
              <documents-form
                :files-list="filesList"
                @remove-file="removeFile"
                @clear-files="clearFiles"
                @save-files="createFiles"
              />
            </b-sidebar>

            <!-- share link modal component -->
            <b-modal
              v-model="isShareLinkModalActive"
              scroll="keep"
              :width="400"
            >
              <share-link-modal
                @share-document="shareLink"
                @close="isShareLinkModalActive = false"
              />
            </b-modal>
          </b-tab-item>

          <b-tab-item label="Influencing">
            <opportunities-influencing-table />
          </b-tab-item>
        </b-tabs>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import DocumentsMixin from '@/mixins/DocumentsMixin';

import { PageHeader } from '@/components/Shared';
import { SearchBar } from '@/components/Search';
import { AccountsOverview, AccountsHedbergTable } from '@/components/Accounts';
import { ContactsTable } from '@/components/Contacts';
import { LeadsTable } from '@/components/Leads';
import { OpportunitiesInfluencingTable, OpportunitiesTable } from '@/components/Opportunities';
import { ActivitiesTable, ActivitiesTabs, NotesCardForm } from '@/components/Activities';

export default {
  name: 'ViewAccount',

  components: {
    PageHeader,
    SearchBar,
    AccountsOverview,
    ContactsTable,
    ActivitiesTable,
    ActivitiesTabs,
    NotesCardForm,
    LeadsTable,
    OpportunitiesInfluencingTable,
    OpportunitiesTable,
    AccountsHedbergTable,
  },

  filters: {
    date(value) {
      return new Date(value).toLocaleDateString();
    },
  },

  mixins: [DocumentsMixin],

  data: () => ({
    page: 1,
    perPage: 50,
    isLoading: true,
    activeTab: 0,
  }),

  computed: {
    ...mapGetters({
      accounts: 'DataGrids/getAccounts',
      account: 'Accounts/getAccountView',
      notes: 'Activities/getNotes',
      activity: 'Activities/getActivity',
      activityTypes: 'Lookups/getActivityTypes',
      customFieldsList: 'Settings/getAccountCustomFields',
      settings: 'Settings/getSettings',
    }),

    childAccounts() {
      if (!this.accounts[0] || !this.accounts[0].childAccounts) return [];
      return this.accounts[0].childAccounts.split(',');
    },

    childAccountIds() {
      if (!this.accounts[0]) return [];
      return this.accounts[0].childAccountIds.split(',');
    },
  },

  async created() {
    try {
      await this.$store.dispatch('Settings/fetchCustomizations');
      await this.fetchAccount(this.$route.params.accountId);
      await this.$store.dispatch('Settings/fetchCustomFields', 'Account');
      await this.fetchOrderQuoteFinancials();
      await this.$store.dispatch('DataGrids/fetchAccounts', {
        page: 0,
        size: 1,
        sort: ['updatedAt', 'desc'],
        filters: `&${new URLSearchParams({ id: this.$route.params.accountId }).toString()}`,
      });
      await this.$store.dispatch('Activities/fetchAccountActivities', {
        accountId: this.$route.params.accountId,
        page: this.page - 1,
        perPage: 50,
        sort: ['createdAt', 'desc'],
        globalSearch: '',
        filters: '',
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },

  mounted() {
    this.setTab(parseInt(this.$route.query.activeTab, 10));
  },

  destroyed() {
    this.$store.commit('Accounts/CLEAR_ACCOUNT');
  },

  methods: {
    ...mapActions({
      fetchAccount: 'Accounts/fetchAccount',
    }),

    openSidebar() {
      this.activeTab = 5;
      this.isSidebarActive = true;
    },

    setTab(value) {
      this.activeTab = value;
    },

    openContactsFormModal() {
      this.$store.commit('Contacts/SET_CONTACT_ACCOUNT_ID', this.$route.params.accountId);
      this.isContactsFormModalActive = true;
    },

    async fetchOrderQuoteFinancials() {
      try {
        if (this.account.externalId) {
          await this.$store.dispatch(
            'OrderQuoteFinancials/fetchAccountOrderQuoteFinancials',
            this.account.externalId,
          );
        }
      } catch (error) {
        console.error(error);
      }
    },

    async createNote() {
      this.isLoading = true;
      this.$store.commit('Activities/SET_ACTIVITY_ACCOUNT_ID', this.$route.params.accountId);
      const activityTypes = [...this.activityTypes];
      activityTypes.forEach((activityType) => {
        if (activityType.value === 'Note') {
          this.$store.commit('Activities/SET_ACTIVITY_ACTIVITY_TYPE_ID', activityType.id);
        }
      });
      const payload = { ...this.activity };
      try {
        if (payload.file.fileName === '') {
          payload.file = null;
        }
        payload.reminder = null;
        await this.$store.dispatch('Activities/createActivity', payload);
        this.$store.commit('Activities/CLEAR_ACTIVITY');
        await this.$store.dispatch('Activities/fetchAccountActivities', {
          accountId: this.$route.params.accountId,
          page: this.page - 1,
          perPage: 50,
          sort: ['createdAt', 'desc'],
          globalSearch: '',
          filters: '',
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    async deleteNote(noteId) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('Activities/deleteActivity', noteId);
        await this.$store.dispatch('Activities/fetchAccountActivities', {
          accountId: this.$route.params.accountId,
          page: this.page - 1,
          perPage: 50,
          sort: ['createdAt', 'desc'],
          globalSearch: '',
          filters: '',
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    async convertToOpportunity(leadId) {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch('Leads/convertToOpportunity', leadId);
        const { id } = response;
        await this.$router.push(`/opportunities/${id}/edit`);
      } catch (error) {
        console.error(error);
      }
    },

    checkOwnership() {
      if (this.settings.allRead) {
        return true;
      }
      if (this.account.owners && this.accounts.length > 0) {
        const ownerId = this.account.owners[0] && this.account.owners[0].owner
          ? this.account.owners[0].owner.id : '';
        const coOwnerId = this.account.owners[1] && this.account.owners[1].owner
          ? this.account.owners[1].owner.id : '';

        const { teamIds } = this.accounts[0];
        if (teamIds) {
          return this.isAccountOwner(ownerId, coOwnerId, this.account.createdBy)
          || this.isManager(ownerId, coOwnerId)
          || this.isTeammate(teamIds);
        }
        return this.isAccountOwner(ownerId, coOwnerId, this.account.createdBy);
      }
      return false;
    },
  },
};
</script>

<style lang="css" scoped></style>
